
import React from 'react';
import  { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar.js';
import Countdown from '../components/Countdown.js';
/*import SpeechCard from '../components/SpeechCard.js';*/
import '../styles/WelcomePage.css';
import '../styles/RegistrationPage.css'

const WelcomePage = () => {
{/*
  const [isVisible, setIsVisible] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.1 } // Adjust this threshold as needed
    );

    if (contentRef.current) {
      observer.observe(contentRef.current);
    }

    return () => {
      if (contentRef.current) {
        observer.unobserve(contentRef.current);
      }
    };
  }, []);*/}
  
    const [isAnimationComplete, setIsAnimationComplete] = useState(false);
  
    useEffect(() => {
      const handleAnimationEnd = () => {
        setIsAnimationComplete(true);
      };
  
      const boxes = document.querySelectorAll('.comm-box');
      boxes.forEach(box => {
        box.addEventListener('animationend', handleAnimationEnd);
      });
  
      return () => {
        boxes.forEach(box => {
          box.removeEventListener('animationend', handleAnimationEnd);
        });
      };
    }, []);
  return (
    <div className="welcome-page">
      <div classname="speeches">
        <Navbar /> 
      <div/>
      <div className="content"> 
        <h1>DELEGATES TODAY,</h1>
        <h1>LEADERS TOMORROW</h1> 
        <br></br>   
        <br></br>
        <p>5-6 OCTOBER, 2024</p>
        <p>@PES UNIVERSITY</p>
        
      </div>

      <div className='welcome'>
        <p>
          
        </p>
        <Countdown />
      {/*
      </div>
      
      <div className='speeches'>
        <SpeechCard 
        imageSrc="c:\Users\abhay\OneDrive\Pictures\Screenshots\Abhay.JPG"
        name="John Doe"
        title="CEO, Company X"
        text="This is an example speech text. Replace this with actual content." />

      <SpeechCard 
        imageSrc="c:\Users\abhay\OneDrive\Pictures\Screenshots\Abhay.JPG"
        name="John Doe"
        title="CEO, Company X"
        text="This is an example speech text. Replace this with actual content." />

      <SpeechCard 
        imageSrc="c:\Users\abhay\OneDrive\Pictures\Screenshots\Abhay.JPG"
        name="John Doe"
        title="CEO, Company X"
        text="This is an example speech text. Replace this with actual content." />

      <SpeechCard 
        imageSrc="c:\Users\abhay\OneDrive\Pictures\AbhayKumar_ViceChair_UNGA.JPG  "
        name="John Doe"
        title="CEO, Company X"
        text="This is an example speech text. Replace this with actual content." />
      */}
      </div>
      <div className="rectangular-component-wp">
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSe78r5i5wdvx6tkkncRaHSvupiaV-vVopzO-2Kcn0CD5d0ing/closedform" target="_blank" rel="noopener noreferrer" style={{ color: '#E0C491', textDecoration:'none' }}>
          <p>INDIVIDUAL REGISTRATION FORM</p>
        </a>      
      </div>
      <div className="rectangular-component-wp">
      <a href="https://docs.google.com/forms/d/e/1FAIpQLSevWNORn7DpWYERn9UMopMrJ6L6v9JKXDB6RqmohIYByUN5YQ/closedform" target="_blank" rel="noopener noreferrer" style={{ color: '#E0C491', textDecoration: 'none' }}>
          <p>DELEGATION REGISTRATION FORM</p>
        </a> 
      </div>
      <div className="rectangular-component-wp">
        <a href="https://docs.google.com/spreadsheets/d/1DHt8hPKtrL4kcLfZnOA2Hh3howAw7i3tpOzKbe7Ws1c/edit?usp=sharing" target="_blank" rel="noopener noreferrer" style={{ color: '#E0C491', textDecoration:'none', justifyContent: 'center', textAlign:'center' }}>
          <p>COUNTRY MATRIX</p>
        </a>      
      </div>
      

      <div className="comm-wp-ani">
      COMMITTEES</div>
      <div className="comm-box-container">
        {/* Row 1 */}
        <div className={`comm-box ${isAnimationComplete ? 'restart-animation' : ''}`}>
          <p>UNSC</p>
        </div>
        <div className={`comm-box ${isAnimationComplete ? 'restart-animation' : ''}`}>
          <p>UNHRC</p>
        </div>
        <div className={`comm-box ${isAnimationComplete ? 'restart-animation' : ''}`}>
          <p>DISEC</p>
        </div>
        <div className={`comm-box ${isAnimationComplete ? 'restart-animation' : ''}`}>
          <p>UNODC</p>
        </div>
        
        {/* Row 2 */}
        <div className={`comm-box ${isAnimationComplete ? 'restart-animation' : ''}`}>
          <p>AIPPM</p>
        </div>
        <div className={`comm-box ${isAnimationComplete ? 'restart-animation' : ''}`}>
          <p>CCC</p>
        </div>
        <div className={`comm-box ${isAnimationComplete ? 'restart-animation' : ''}`}>
          <p>INTERPOL</p>
        </div>
        <div className={`comm-box ${isAnimationComplete ? 'restart-animation' : ''}`}>
          <p>IPC</p>
        </div>
      </div>
      <div className='spaceee'>
        <hr color='#04352A'></hr>
      </div>
    </div>
    <div className="comm-wp-ani" style={{padding: '0px'}}>
      USEFUL LINKS
    </div>
    <ul className="some-new-shit" >
        <li><Link to="/">HOME</Link></li>
        {/*<li><Link to="/aboutus">ABOUT US</Link></li>*/}
        <li><Link to="/register">REGISTER</Link></li>
        <li style={{paddingBottom: '20px'}}><Link to="/committees">COMMITTEES</Link></li>
        {/*<li><Link to="/resources">RESOURCES</Link></li>*/}
      </ul>
    <div className="comm-wp-ani" style={{padding: '0px'}}>
      HELP DESK
    </div>
    <ul className='some-new-shit'>
      <li>Shanmuga Teja : +91 9945919672</li>
      <li style={{paddingBottom: '100px'}}>Rejith Kumar : +91 9740268078</li>
    </ul>
    </div>
  );
};

export default WelcomePage;
