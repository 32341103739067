/* import React, { useState, useEffect } from 'react';
import Header from '../components/Header.js';
import DropdownMenuRegs from '../components/DropdownMenu_Regs.js';
import '../styles/RegistrationPage.css';

const RegistrationPage = () => {
  const [showContact, setShowContact] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const triggerHeight = 10; // Trigger after scrolling 300px
      if (scrollPosition > triggerHeight) {
        setShowContact(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="registration-page">
      <Header />
      <DropdownMenuRegs />
      <main>
        
        <div className="registration-container">
          <div className='register-here'>
            <h1>Register Now!!!</h1>
          </div>      
          <div className="rectangular-component">
            <p>INDIVIDUAL REGISTRATION FORM</p>
          </div>
          <div className="rectangular-component">
            <p>DELEGATION REGISTRATION FORM</p>
          </div>
        </div>

        
        <div style={{ height: '100px' }}></div>

        {showContact && (
          <div className="contact-us">
            <h2>CONTACT US</h2>
            <div className="rectangular-contact-component">
              STUTI, SECRETARY GENERAL<br />
              9888989898      
            </div>

            <div className="rectangular-contact-component">
              ISHAAN, DEPUTY SECRETARY GENERAL<br />
              9888989898      
            </div>

            <div className="rectangular-contact-component">
              GARIMA, DEPUTY SECRETARY GENERAL<br />
              9888989898      
            </div>

            <div className="rectangular-contact-component">
              Mail us at <br />
              pecon@pes.edu     
            </div>

            <div className="rectangular-contact-component">
              <a href="https://www.instagram.com/pesmunsociety/?hl=en">
                Our Insta might interest you. Have a look?
              </a>      
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default RegistrationPage;
*/

import React, { useState, useEffect } from 'react';
import Navbar from '../components/Navbar.js'
import '../styles/RegistrationPage.css';

const RegistrationPage = () => {
  const [showContact, setShowContact] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const triggerHeight = 10; // Trigger after scrolling 300px
      const scrollPosition = window.scrollY;
      if (scrollPosition > triggerHeight) {
        setShowContact(true);
      } else {
        setShowContact(false); // Hide the section if scrolled up
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="registration-page">
      <Navbar />
      <main>
        <div className="registration-container">
          <div className="register-here">
            <h1>Register Now</h1>
          </div>
          <div className="rectangular-component">
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSe78r5i5wdvx6tkkncRaHSvupiaV-vVopzO-2Kcn0CD5d0ing/closedform" target="_blank" rel="noopener noreferrer" style={{ color: '#E0C491', textDecoration: 'none'}}>
          <p>INDIVIDUAL REGISTRATION FORM</p>
        </a>
          </div>
          <div className="rectangular-component">
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSevWNORn7DpWYERn9UMopMrJ6L6v9JKXDB6RqmohIYByUN5YQ/closedform" target="_blank" rel="noopener noreferrer" style={{ color: '#E0C491', textDecoration: 'none'}}>
          <p>DELEGATION REGISTRATION FORM</p>
        </a>          </div>
        <div className="rectangular-component">
          <a href="https://docs.google.com/spreadsheets/d/1DHt8hPKtrL4kcLfZnOA2Hh3howAw7i3tpOzKbe7Ws1c/edit?usp=sharing" target="_blank" rel="noopener noreferrer" style={{ color: '#E0C491', textDecoration: 'none'}}>
          <p>COUNTRY MATRIX</p>
        </a>
          </div>
        </div>

        {/* Placeholder content to ensure scrolling */}
        <div style={{ height: '50px' }}></div>

        {/* Contact Us Section */}
        <div className={`contact-us ${showContact ? 'fade-in' : ''}`}>
          <h2>CONTACT US</h2>
          <div className="rectangular-contact-component">
            STUTI PATHAK<br />
            +91 9972309732
          </div>

          <div className="rectangular-contact-component">
            SHANMUGA TEJA<br />
            +91 9945919672
          </div>

          <div className="rectangular-contact-component">
            REJITH KUMAR<br />
            +91 9740268078
          </div>

          <div className="rectangular-contact-component">
            Mail us at <br />
            pecon@pes.edu
          </div>

          <div className="rectangular-contact-component">
            <a href="https://www.instagram.com/pesmunsociety/?hl=en" style={{textDecoration: "none"}}>
              INSTAGRAM
            </a>
          </div>
        </div>
      </main>
    </div>
  );
};

export default RegistrationPage;

