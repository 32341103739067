import React, { useState, useEffect } from 'react';
import '../styles/Countdown.css'; // Ensure you have this CSS file

const Countdown = () => {
  const [timeRemaining, setTimeRemaining] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });

  useEffect(() => {
    const targetDate = new Date('2024-10-05T09:00:00');

    const updateCountdown = () => {
      const now = new Date();
      const timeDiff = targetDate - now;

      if (timeDiff <= 0) {
        setTimeRemaining({ days: 0, hours: 0, minutes: 0, seconds: 0 });
        return;
      }

      const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

      setTimeRemaining({ days, hours, minutes, seconds });
    };

    // Update countdown every second
    const countdownInterval = setInterval(updateCountdown, 1000);

    // Initial call
    updateCountdown();

    // Clean up interval on component unmount
    return () => clearInterval(countdownInterval);
  }, []);

  return (
    <div className="countdown">
      {/*<div className="countdown-text">REGISTRATIONS OPEN IN</div>*/}

      <div id="countdown-timer">
      {/*
      <div><h1 align="bottom" font-size="2em">REGISTRATIONS BEGIN IN: </h1><br></br></div>
      <br></br>
      */}
        <div className="countdown-item">
          <span className="countdown-number">{timeRemaining.days}</span>
          <span className="countdown-label">Days</span>
        </div>
        <div className="countdown-item">
          <span className="countdown-number">{timeRemaining.hours}</span>
          <span className="countdown-label">Hours</span>
        </div>
        <div className="countdown-item">
          <span className="countdown-number">{timeRemaining.minutes}</span>
          <span className="countdown-label">Minutes</span>
        </div>
        <div className="countdown-item">
          <span className="countdown-number">{timeRemaining.seconds}</span>
          <span className="countdown-label">Seconds</span>
        </div>
      </div>
    </div>
  );
};

export default Countdown;
