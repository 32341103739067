import React from 'react';
import Navbar from '../components/Navbar';
import '../styles/ComingSoonPage.css';

const ComingSoonPage = () => {
  return (
    <div className="coming-soon-page">
        <div className="coming-soon-page-navbar"><Navbar /></div>
      <h1 className='csp-h1'>Coming Soon..</h1>
    </div>
  );
};

export default ComingSoonPage;
