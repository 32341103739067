import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Navbar.css';

const Navbar = () => {
  const [scrollProgress, setScrollProgress] = useState(0);

  useEffect(() => {
    const updateProgressBar = () => {
      const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
      const scrolled = (scrollTop / (scrollHeight - clientHeight)) * 100;
      setScrollProgress(scrolled);
    };

    window.addEventListener('scroll', updateProgressBar);
    return () => window.removeEventListener('scroll', updateProgressBar);
  }, []);

  return (
    <nav className="navbar">
      <ul className="navbar-menu">
        <li><Link to="/">HOME</Link></li>
        <li><Link to="/aboutus">ABOUT US</Link></li>
        <li><Link to="/register">REGISTER</Link></li>
        <li><Link to="/committees">COMMITTEES</Link></li>
        <li><Link to="/resources">RESOURCES</Link></li>
      </ul>
      <div className="navbar-icons">
        <a href="https://www.instagram.com/pesmunsociety/" target="_blank" rel="noopener noreferrer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="#E0C491"
            width="24px"
            height="24px"
          >
            <path d="M12 2.163c3.204 0 3.584.012 4.85.07 1.366.063 2.633.325 3.608 1.3.975.975 1.237 2.242 1.3 3.608.058 1.266.07 1.646.07 4.85s-.012 3.584-.07 4.85c-.063 1.366-.325 2.633-1.3 3.608-.975.975-2.242 1.237-3.608 1.3-1.266.058-1.646.07-4.85.07s-3.584-.012-4.85-.07c-1.366-.063-2.633-.325-3.608-1.3-.975-.975-1.237-2.242-1.3-3.608-.058-1.266-.07-1.646-.07-4.85s.012-3.584.07-4.85c.063-1.366.325-2.633 1.3-3.608.975-.975 2.242-1.237 3.608-1.3 1.266-.058 1.646-.07 4.85-.07m0-2.163c-3.257 0-3.667.014-4.947.072-1.276.058-2.86.324-3.995 1.458-1.135 1.136-1.4 2.719-1.458 3.995-.058 1.28-.072 1.69-.072 4.947s.014 3.667.072 4.947c.058 1.276.324 2.86 1.458 3.995 1.136 1.135 2.719 1.4 3.995 1.458 1.28.058 1.69.072 4.947.072s3.667-.014 4.947-.072c1.276-.058 2.86-.324 3.995-1.458 1.135-1.136 1.4-2.719 1.458-3.995.058-1.28.072-1.69.072-4.947s-.014-3.667-.072-4.947c-.058-1.276-.324-2.86-1.458-3.995-1.136-1.135-2.719-1.4-3.995-1.458-1.28-.058-1.69-.072-4.947-.072zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.162 6.162 6.162 6.162-2.759 6.162-6.162-2.759-6.162-6.162-6.162zm0 10.162c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zm6.406-11.845c-.796 0-1.441.646-1.441 1.442s.645 1.441 1.441 1.441 1.442-.646 1.442-1.441-.646-1.442-1.442-1.442z" />
          </svg>
        </a>
        <a href="https://www.linkedin.com/company/pesmunsoc/posts/?feedView=all" target="_blank" rel="noopener noreferrer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="#E0C491"
            width="24px"
            height="24px"
          >
            <path d="M19 0h-14c-2.76 0-5 2.24-5 5v14c0 2.76 2.24 5 5 5h14c2.76 0 5-2.24 5-5v-14c0-2.76-2.24-5-5-5zm-11 19h-3v-10h3v10zm-1.5-11.25c-.97 0-1.75-.79-1.75-1.75s.79-1.75 1.75-1.75 1.75.79 1.75 1.75-.78 1.75-1.75 1.75zm13.5 11.25h-3v-5.5c0-1.1-.9-2-2-2s-2 .9-2 2v5.5h-3v-10h3v1.25c.82-.91 2-1.25 3.25-1.25 2.49 0 4.5 2.01 4.5 4.5v5.5z" />
          </svg>
        </a>
        <a href="https://www.google.com/maps/place/PES+University/@12.9350817,77.5334604,17z/data=!3m1!4b1!4m6!3m5!1s0x3bae3e468d8d36d3:0x694d74f6ac640acf!8m2!3d12.9350817!4d77.5360407!16zL20vMDYwcjB3?entry=ttu" target="_blank" rel="noopener noreferrer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="#E0C491"
            width="24px"
            height="24px"
          >
            <path d="M12 2C7.029 2 3.5 5.539 3.5 10.5S12 22 12 22s8.5-6.559 8.5-11.5S16.971 2 12 2zm0 16c-2.217 0-4-1.783-4-4s1.783-4 4-4 4 1.783 4 4-1.783 4-4 4zm0-7c-1.657 0-3 1.343-3 3s1.343 3 3 3 3-1.343 3-3-1.343-3-3-3z" />
          </svg>
        </a>
      </div>
      <div className="scroll-progress" style={{ width: `${scrollProgress}%` }}></div>
    </nav>
  );
};

export default Navbar;
