
import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import '../styles/bg.css';

function UNSCbg() {
  return (
    <div className="pdf-page">
      <Navbar />
      <div className="pdf-container">
        <object data={require('./doc.pdf')} type="application/pdf" width="100%" height="100%">
          <p>Your browser does not support PDFs. Please download the PDF to view it: <a href={require('./doc.pdf')}>Download PDF</a>.</p>
        </object>
      </div>
      <Link to="/resources" className="back-button">Back</Link>
    </div>
  );
}

export default UNSCbg;
